<!--
 * @Descripttion: 项目详情
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 09:52:55
-->
<template>
	<div class="bg vh">
		<van-nav-bar title="项目详情" left-arrow @click-left="$router.push('/record')" />
		<div class="whiteBg" style="margin-top:0;position: relative;">
			<span v-if="projectInfo.projectStatus =='00'" class="rightTop wait">待抽取</span>
			<span v-else-if="projectInfo.projectStatus =='01'" class="rightTop doing">抽取中</span>
			<span v-else-if="projectInfo.projectStatus =='02'" class="rightTop over">抽取完成</span>
			<span v-else class="rightTop abolish">已终止</span>

			<h3 style="width: calc(100% - 1.6rem)">
				{{currentViewProject.projectName}}
			</h3>

		</div>
		<div class="pad-row" style="padding-bottom:0.5rem">
			<div class="whiteBg">
				<p><span>项目编号</span><span>{{projectInfo.projectNum}}</span></p>
				<p><span>项目名称</span><span>{{projectInfo.projectName}}</span></p>
				<p><span>采购单位</span><span>{{projectInfo.purchaseOrg}}</span></p>
				<p><span>评审时间</span><span>{{projectInfo.reviewDatetime}}</span></p>
				<p><span>评审地点</span><span>{{projectInfo.reviewSite}}</span></p>
			</div>

			<div class="whiteBg">
				<p><span>包组编号</span><span>{{projectInfo.packageNum}}</span></p>
				<p><span>专家人数</span><span>{{projectInfo.expertNum}}</span></p>
				<p><span>评审专业</span>
					<span>
						<template v-for="(majorItem) in majorList">
							<p>{{majorItem.parentMajorName}}-{{majorItem.majorName}}</p>
						</template>
					</span>
				</p>
			</div>
			<!-- 管理员显示该信息 -->
			<div class="whiteBg" v-if="isAdmin">
				<div class="flex-between">
					<h3>允许普通操作员查看该项目</h3>
					<van-switch v-model="onShowFlag" active-color="#00CCB2" @click="onChangeShowFlag" />
				</div>
			</div>


			<!-- 若项目未抽取则不显示一下信息 -->
			<div class="whiteBg">
				<div class="flex-between">
					<!-- <span class="gray2">抽取结果</span> -->
					<h3>抽取结果</h3>
					<button v-if="showAddExpertBtn" class="msgBtn fr" @click="handleAddExpert">补录专家</button>
					<button @click="sendSms" class="msgBtn fr" :class="{disabled : !showBtn}" :disabled="!showBtn" v-show="vshowBtn"
					 v-if="projectInfo.projectStatus =='02'">
						{{ btnText }}
					</button>
				</div>
				<div class="flex-between mar-t2" v-for="(expertRecord) in currentViewProject.inExpertRecords" :key="expertRecord.id">
					<div class="tempW">
						<h3 class="fc inline mar-r2 f45">{{expertRecord.expertName}}</h3>
						<h3 class="inline f45">{{expertRecord.expertPhone}}</h3>
						<div v-if="expertRecord.majorName">
							<p class="">{{ expertRecord.majorParentName }}-{{ expertRecord.majorName }}</p>
						</div>
					</div>
					<div class="flex-item" v-if="expertRecord.noAttendReason==''||expertRecord.noAttendReason==null">
						<span class="red fw" @click="handleLeaveInfo(expertRecord)">请假</span>
					</div>
					<div class="flex-item">
						<span class="blue fw" v-if="expertRecord.noAttendReason">请假</span>
						<span class="blue fw" v-else>参与</span>
						<van-icon name="arrow" color="#C7C7CC" size="0.5rem" @click="showRecord(expertRecord)" />
					</div>
				</div>
			</div>

			<div class="whiteBg">
				<h3>抽取记录</h3>
				<div v-for="(item,index) in currentViewProject.extractRoundVoList" :key="index">
					<div class="flex-between mar-t">
						<span class="gray2">第{{index+1}}轮专家确认</span>
					</div>
					<div class="flex-between" v-for="(childItem,index1) in item.recordList" :key="index1">
						<template v-if="childItem.expertId">
							<div class="tempW">
								<h3 class="fc inline mar-r2 f45">{{childItem.expertName}}</h3>
								<h3 class="inline f45">{{childItem.expertPhone}}</h3>
								<div v-if="childItem.majorName">
									<p class="">{{ childItem.majorParentName }}-{{ childItem.majorName }}</p>
								</div>
							</div>
							<div class="flex-item">
								<span :class="{'blue':childItem.ifAttend =='1', 'yellow':childItem.ifAttend=='0', fw:true}">{{ childItem.ifAttend =='0' ? '不参与' : '参与'}}</span>
								<van-icon name="arrow" color="#C7C7CC" size="0.5rem" @click="showRecord(childItem)" />
							</div>
						</template>
					</div>
				</div>
			</div>

			<van-popup v-model="showExpertInfo" class="div7" round>
				<div class="ttt">
					<h3 class="fc">{{ expert.expertName }}</h3>
					<p class="mar-t2">
						手机号： {{ expert.expertPhone }}
						<a :href="'tel:' + expert.expertPhone "><i class="icon-phone wh05 mar-l2"></i></a>
					</p>
					<div v-if="expert.majorName" class="mar-t2">
						<h3>评审专业</h3>
						<p class="greenLi van-ellipsis">{{ expert.majorParentName + '-' + expert.majorName }}</p>
					</div>
					<p class="mar-t2">是否参与</p>
					<van-radio-group v-model="expert.ifAttend" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
						<van-radio name="1" class="isAttend">参加</van-radio>
						<van-radio name="0" class="isAttend">不参加</van-radio>
					</van-radio-group>
					<p v-if="expert.noAttendReason" class="mar-t2">请假原因:</p>
					<p v-if="expert.noAttendReason" class="red"> {{ expert.noAttendReason }}</p>
					<!-- <div class="center">
						<button @click="showExpertInfo = false">关闭</button>
						<button @click="showExpertInfo = false">关闭</button>
					</div> -->
					<div class="flex mar-t">
						<button class="btn div4" style="margin-right:10%;background-color:red" v-if="expert.expertId==''||expert.expertId==null" @click="deleteAddExpert(expert.id)">删除</button>
						<button class="btn div4" @click="showExpertInfo = false">关闭</button>
					</div>					
				</div>
			</van-popup>
			<!--请假Modal 开始 -->
			<van-popup v-model="showLeaveInfo" class="div7" round>
				<div class="ttt">
					<h3 class="fc">{{ leaveItem.expertName }}</h3>
					<div>
							<textarea name="remark" rows="3" class="w-100 pad" v-model="leaveItem.noAttendReason" placeholder="在这里填写请假原因…"></textarea>
					</div>
					<div class="flex mar-t">
						<button class="btn cancel div4" style="margin-right:10%" @click="cancleLeaveInfo">取消</button>
						<button class="btn div4" @click="onSaveLeave">确认</button>
					</div>
				</div>
			</van-popup>
			<!--请假Modal 结束 -->
			<!--补录专家Modal 开始 -->
			<van-popup v-model="addExpertModal" class="div9" round>
				<div class="addExpert">
					<h3 class="fc center">补录专家</h3>
					<div>
							<p>专家姓名<span style="color:red">*</span></p>
							<input type="text" placeholder="请输入" v-model="addExpertItem.expertName">
					</div>
					<div>
							<p>手机号<span style="color:red">*</span></p>
							<input type="text" placeholder="请输入" v-model="addExpertItem.expertPhone">
					</div> 
					<div class="flex mar-t">
						<button class="btn cancel div4" style="margin-right:10%" @click="cancleAddExpertModal">取消</button>
						<button class="btn div4" @click="onSaveAddExpert">保存</button>
					</div>
				</div>
			</van-popup>
			<!--补录专家Modal 结束 -->

		</div>
	</div>
</template>

<script>
	import {
		Switch,
		Icon,
		Popup,
		RadioGroup,
		Radio,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				isHide: true,
				projectInfo: {},
				majorList: [],
				addApply: true, //补抽状态
				currentViewProject: {
					extractRoundVoList: [],
					inExpertRecords: [],
					allExpertRecords: [],
				},
				showExpertInfo: false,
				expert: {},
				isAdmin: true,
				btnText: '短信一键通知',
				showBtn: true,
				timer: 120,
				timeInterval: '',
				vshowBtn: false,
				workweixin: false,				//企业微信
				openUserid:	"",
				showLeaveInfo: false,
				backupLeaveItem:{},
				leaveItem:{},
				url:{
          updateFinishedProject: "/gateway/ed/project/tbEdProjectExtractExpertRecord/updateFinishedProject",
          addExpert:"/gateway/ed/project/tbEdProjectExtractExpertRecord/addExpert",
          delete:"/gateway/ed/project/tbEdProjectExtractExpertRecord/delete"					
				},
				showAddExpertBtn: false,
				addExpertModal: false,
				addExpertItem:{
          expertName: '',
          expertPhone: '',
          projectId:''					
				},

			}
		},
		components: {
			[Icon.name]: Icon,
			[Switch.name]: Switch,
			[Popup.name]: Popup,
			[RadioGroup.name]: RadioGroup,
			[Radio.name]: Radio
		},

		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//获取公司信息
			if (this.$ls.get("currentProjectInfo")) {
				this.projectInfo = this.$ls.get("currentProjectInfo");
				// this.majorList = this.projectInfo.majorList;
			}
			//查询项目的专家抽取信息
			this.selectExpertInfo();
			/** 获取该用户是否为管理员 */
			this.isAdmin = this.$ls.get('edCurrentUser').adminFlag == '1' ? true : false;
			/** 若用户处于使用期或已开通服务或者开通服务后服务到期都可显示短信服务菜单 */
			if (this.$ls.get('edCurrentOrgExternal') &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus != '00') {
				this.vshowBtn = true;
			} else {
				this.vshowBtn = false;
			}

		},
		computed: {
			//项目信息-操作员是否可见
			onShowFlag() {
				if (this.currentViewProject.showFlag == '1') {
					return true;
				} else {
					return false;
				}
			}
		},

		watch: {
			timer(newV, oldV) {
				if (newV == 0) {
					clearInterval(this.timeInterval)
					this.btnText = '短信一键通知'
					this.showBtn = true
					this.timer = 120
				}
			},
      'currentViewProject.inExpertRecords'(val){
        this.cmpInExpertRecords(val);
      },			
		},
		methods: {
			//查询项目的专家抽取信息
			selectExpertInfo() {
				let params = new URLSearchParams()
				params.append("projectId", this.projectInfo.id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				// params.append("openid",this.store.openid)
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdProjectController/selectByProjectId/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.currentViewProject = res.data.result;
						let inExpertRecords = this.currentViewProject.inExpertRecords;
						let allExpertRecords = this.currentViewProject.allExpertRecords;
						this.majorList = this.currentViewProject.majorList;
						//如果需求专家数和参与专家数
						if (this.projectInfo.expertNum == inExpertRecords.length) {
							this.addApply = false;
						} else {
							let j = 0;
							for (let i = 0; i < allExpertRecords.length; i++) {
								if (allExpertRecords[i].ifAttend == '2') {
									j++;
								}
							}
							if (j > 0) {
								this.addApply = false;
							} else {
								this.addApply = true;
							}
						}
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},

			//修改非管理员不可查看该项目 状态
			onChangeShowFlag() {
				if (this.currentViewProject.showFlag == '1') {
					this.currentViewProject.showFlag = '0'
				} else if (this.currentViewProject.showFlag == '0') {
					this.currentViewProject.showFlag = '1'
				} else {
					this.currentViewProject.showFlag = '0'
				}
				let objData = {
					id: this.currentViewProject.id,
					orgId: this.currentViewProject.orgId,
					showFlag: this.currentViewProject.showFlag
				};
				this.$ajax({
					url: "/gateway/ed/wx/wxEdProjectController/updateShowFlag",
					method: "post",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					data: objData,
				}).then(res => {
					if (res.data.success) {
						this.$toast.success('修改成功');
					} else {
						this.$toast.fail(res.data.message)
					}
				});
			},


			showRecord(item) {
				this.expert = item;
				this.showExpertInfo = true;
			},

			/**
			 * @Description: 发送短信
			 * @Author: Niklaus
			 * @Date: 2021-03-26 16:02:12
			 */
			sendSms: function() {
				let _this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/ed/sms/sendSms",
					method: "get",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: this.projectInfo.id,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						_this.showBtn = false
						_this.btnText = "倒计时" + _this.timer + "s"
						this.timeInterval = setInterval(() => {
							_this.showBtn = false
							_this.timer--;
							_this.btnText = "倒计时" + _this.timer + "s"
						}, 1000);
					} else if (res.status == "200" && (res.data.retCode == "2" || res.data.retCode == "3")) {
						this.btnText = '短信一键通知'
						this.showBtn = true
						this.timer = 120
						Dialog.confirm({
							title: '开通短信服务',
							message: res.data.errMsg,
							confirmButtonText: '去开通服务',
							cancelButtonText: '取消',
						}).then(() => { //确认
							this.$router.push({
								path: '/smsrecharge'
							});
						}).catch(() => {
							console.log('取消')
						})
					} else {
						this.btnText = '短信一键通知'
						this.showBtn = true
						this.timer = 120
						this.$toast({
							message: res.data.errMsg,
							type: 'fail',
							duration: 5000
						})
					}
				}).catch((err) => {
					this.$toast({
						message: "请求错误",
						type: 'fail',
						duration: 5000
					})
					this.btnText = '短信一键通知'
					this.showBtn = true
					this.timer = 120
					console.log(err)
				})
			},
			//专家请假弹框
			handleLeaveInfo(record){
				this.showLeaveInfo = true;
				this.backupLeaveItem = record;
				Object.assign(this.leaveItem,record);

			},
			//关闭请假弹框
			cancleLeaveInfo(){
				this.showLeaveInfo = false;
			},
			//保存请假数据
			onSaveLeave(){
       if(this.leaveItem.noAttendReason){
          this.leaveItem.noAttendReason = this.leaveItem.noAttendReason.trim();
          if(this.leaveItem.noAttendReason.length>100){
            this.$toast.fail('请假原因不得超过100个字符长度');					
            return;
          }  
        }else{
					this.$toast.fail('请输入请假原因');					
          return;       
        }				
        this.$ajax({
          url: this.url.updateFinishedProject,
          method: "put",
          headers: {
            "X-Access-Token": this.$ls.get("token"),
          },
          data: this.leaveItem,
        }).then(res=>{
          if(res.data.success){
            this.backupLeaveItem.noAttendReason = this.leaveItem.noAttendReason;
            this.cmpInExpertRecords(this.currentViewProject.inExpertRecords);
						this.$toast.success(res.data.message)
          }else{
						this.$toast.fail(res.data.message);					
          }
        }).catch(err=>{
					this.$toast.fail(JSON.stringify(err));					
        }).finally(()=>{
          this.showLeaveInfo = false;
        });
			},
      //计算参与专家数，控制补录专家按钮
      cmpInExpertRecords(inExpertRecords){
         let i = 0;//请假人数
         let total = inExpertRecords.length;
         inExpertRecords.forEach(element => {
           if(element.noAttendReason&&element.noAttendReason!=null){
             i++;
           }
         });
         if((total-i)<this.currentViewProject.expertNum){
           this.showAddExpertBtn = true;
         }else{
           this.showAddExpertBtn = false;
         }
      },
			//补录专家弹框
			handleAddExpert(){
				this.addExpertModal = true;
			},
			//取消补录专家弹框
			cancleAddExpertModal(){
				this.addExpertModal = false;
			},
			//保存补录专家
			onSaveAddExpert(){	
				
        this.addExpertItem.expertName = this.addExpertItem.expertName.trim();
        this.addExpertItem.expertPhone = this.addExpertItem.expertPhone.trim();
        if(this.addExpertItem.expertName==''){
					this.$toast.fail('专家姓名不能为空');				
          return;
        } 
        if(this.addExpertItem.expertPhone==''){
					this.$toast.fail('手机号不能为空');				
          return;
        } 
        if(this.addExpertItem.expertName.length>100){
					this.$toast.fail('专家姓名长度不得超过100个字符');				
          return;
        }         
        if(this.addExpertItem.expertPhone.length>30){
					this.$toast.fail('手机号长度不得超过30个字符');				
          return;
        } 
				this.addExpertModal = false;
        //数据准备
        this.addExpertItem.projectId = this.projectInfo.id;
        //post
        this.$ajax({
          url: this.url.addExpert,
          method: "post",
          headers: {
            "X-Access-Token": this.$ls.get("token"),
          },
          data: this.addExpertItem,
        }).then(res=>{
          if(res.data.success){
            this.currentViewProject.inExpertRecords.push(res.data.result);
						this.$toast.success('补录成功');				
          }else{
						this.$toast.fail(res.data.message);				
          }
        }).catch(err=>{
					this.$toast.fail(JSON.stringify(err));				
        }).finally(()=>{
          
        });				
			},
			//删除的补录的专家
			deleteAddExpert(id){
				Dialog.confirm({
					title: '删除提示',
					message:
						'确认删除此专家信息吗？',
				})
					.then(() => {
		        //删除
            this.$ajax({
                url: this.url.delete,
                method: "delete",
                headers: {
                    "X-Access-Token":this.$ls.get("token"),
                },
                params: {
                  id:id
                },
            }).then(res=>{
              if(res.data.success){
                let list = [];
                this.currentViewProject.inExpertRecords.forEach(item=>{
                  if(item.id!=id){
                    list.push(item);
                  }
                })
                this.currentViewProject.inExpertRecords = list;
								this.$toast.success('删除专家数据成功！');	
								this.showExpertInfo = false;			
              }else{
								this.$toast.fail('删除专家数据失败！');			
              }
            });									
					})
					.catch(() => {
						// on cancel
					})
			}

		}
	}
</script>

<style scoped>
	.whiteBg {
		padding: 0.3rem;
		margin-top: 0.4rem;
	}

	.whiteBg>p {
		display: inline-table;
		border-bottom: 1px solid #faf5f5;
		width: 100%;
	}

	.whiteBg>p>span {
		display: table-cell;
		padding: 0.1rem 0;
	}

	.whiteBg>p>span:first-child {
		width: 2rem;
		font-weight: bold;
		color: #1E1E1E;
	}

	.whiteBg>p>span:last-child {
		color: #737373;
	}

	.waitBg {
		background-color: #ffffff;
		padding: 0.5rem;
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		align-items: center;
	}

	.waitBg>img {
		width: 60%;
		height: auto
	}

	.waitBg>button {
		border: 1px solid #80E6D9;
		color: #80E6D9;
		background-color: white;
		padding: 0.1rem 0.6rem;
		border-radius: 0.5rem;
		margin-top: 0.3rem;
	}

	.ttt {
		background-color: #fff;
		padding: 0.5rem;
	}

	.ttt button {
		padding: 0.08rem 0.5rem;
		background-color: #00CCB2;
		color: white;
		margin-top: 1rem;
		display: inline-block;
	}
	.addExpert{
		background-color: #fff;
		padding: 0.5rem 0.1rem;		
	}
	.addExpert button {
		padding: 0.08rem 0.1rem;
		background-color: #00CCB2;
		color: white;
		margin-top: 0.1rem;
		display: inline-block;
	}	
	.addExpert>div{padding: 0.1rem 0.5rem;display: flex; display: -webkit-flex;align-items: center; line-height: 1rem;position: relative;}
	.addExpert>div>p{width: 2rem; font-weight: 800;}
	.addExpert>div>i{pointer-events: none;}
	.addExpert>div>span{display: inline-block; margin-left:0.3rem; margin-right: 0.5rem;}

	.isAttend {
		pointer-events: none
	}

	.tempW {
		width: calc(100% - 2.35rem);
	}

	.disabled {
		pointer-events: none;
		cursor: not-allowed;
		background: #b2bec3;
		color: #fff;
	}
</style>
